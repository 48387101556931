.news-filter {
	margin-bottom: 9rem;

	@include minW(992) {
		display: flex;
		flex-wrap: nowrap;
		margin: 0 -2.7rem;
		justify-content: center;
	}

	&__item {
		@include minW(992) {
			width: 33.3333%;
			padding: 0 2.7rem;
		}

		.btn {
			width: 100%;

			.icon {
				position: absolute;
				top: 50%;
				right: 1.2rem;
				transform: translateY(-50%);
			}
		}
	}
}
.text-center {
	text-align: center;
}
.mb-8 {
	margin-bottom: 8rem;
}
