.fifty-fifty {
	position: relative;
	overflow: hidden;

	&--black {
		background-color: $black-color;
		color: $white-color;
	}

	&--reverse {
		.fifty-fifty {
			&__main {
				flex-direction: row-reverse;
			}
		}
	}

	&--46 {
		.fifty-fifty {
			&__thumb {
				@include minW(768) {
					width: 41.667%;
					margin-bottom: 0;
					min-height: 54rem;
				}
			}
		}

		.is-skew-left,
		.is-skew-right {
			&::after {
				background-color: #000;
			}
		}
	}

	&__main {
		@include minW(768) {
			display: flex;
			align-items: center;
		}
	}

	&__thumb {
		min-height: 28rem;
		position: relative;
		overflow: hidden;

		@include minW(768) {
			width: 50%;
			margin-bottom: 0;
			min-height: 54rem;
		}

		.img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__video {
		position: relative;
		overflow: hidden;

		@include minW(768) {
			width: 50%;
			margin-bottom: 0;
		}

		video {
			display: block;
			width: 100%;
		}
	}

	&__body {
		padding: 4rem 2rem;

		@include minW(768) {
			margin: 0 8.33333333%;
			width: 33.33333333%;
			padding: 0 2rem;
		}
	}

	&__content {
		@include minW(768) {
			padding: 4rem 0;
		}
	}

	&__title {
		text-transform: uppercase;
	}
}

.four-six {
	position: relative;
	overflow: hidden;
	margin-bottom: 6rem;

	&--black {
		background-color: $black-color;
		color: $white-color;
	}

	&--reverse {
		.four-six {
			&__main {
				flex-direction: row-reverse;
			}

			&__body {
				@include minW(768) {
					padding-left: 0;
					padding-right: 4rem;
				}
			}
		}
	}

	&__main {
		@include minW(768) {
			display: flex;
			align-items: center;
		}
	}

	&__thumb {
		min-height: 28rem;
		position: relative;
		overflow: hidden;

		@include minW(768) {
			width: 60%;
			margin-bottom: 0;
			min-height: 54rem;
		}

		.img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__video {
		position: relative;
		overflow: hidden;

		@include minW(768) {
			width: 40%;
			margin-bottom: 0;
		}

		video {
			display: block;
			width: 100%;
		}
	}

	&__body {
		padding: 2rem 0;

		@include minW(768) {
			width: 40%;
			padding-left: 4rem;
		}
	}

	&__content {
		@include minW(768) {
			padding: 4rem 0;
		}
	}

	// &__title {
	// 	text-transform: uppercase;
	// }
}
