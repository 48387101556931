.icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;

	&--location-grey {
		width: 1.6rem;
		height: 1.6rem;
		background-image: url('../img/icons/location-grey.svg');
	}

	&--location-line-black {
		width: 1.7rem;
		height: 2.3rem;
		background-image: url('../img/icons/location-line-black.svg');
	}

	&--location-black {
		width: 3.5rem;
		height: 4.2rem;
		background-image: url('../img/icons/location-black.svg');
	}

	&--location-red {
		width: 3.3rem;
		height: 3.9rem;
		background-image: url('../img/icons/location-red-bg.svg');
	}

	&--mail {
		width: 1.4rem;
		height: 1.1rem;
		background-image: url('../img/icons/mail.svg');
	}

	&--phone {
		width: 1.4rem;
		height: 1.3rem;
		background-image: url('../img/icons/phone.svg');
	}

	&--phone-red {
		width: 1.4rem;
		height: 1.3rem;
		background-image: url('../img/icons/phone-red.svg');
	}

	&--mail_grey {
		width: 1.5rem;
		height: 1.1rem;
		background-image: url('../img/icons/email_grey.svg');
	}

	&--phone_grey {
		width: 1.4rem;
		height: 1.3rem;
		background-image: url('../img/icons/phone_grey.svg');
	}

	&--lap {
		width: 1.4rem;
		height: 1.1rem;
		background-image: url('../img/icons/lap.svg');
	}

	&--lap-red {
		width: 1.4rem;
		height: 1.1rem;
		background-image: url('../img/icons/lap-red.svg');
	}

	&--download {
		width: 2.1rem;
		height: 2.1rem;
		background-image: url('../img/icons/download.svg');
	}

	&--download-2 {
		width: 1.8rem;
		height: 1.8rem;
		background-image: url('../img/icons/download-2.svg');
	}

	&--smartphone {
		height: 2rem;
		width: 2rem;
		background-image: url('../img/icons/smartphone.png');
	}

	&--search {
		width: 1.6rem;
		height: 1.6rem;
		background-image: url('../img/icons/search.svg');
	}

	&--search-black {
		width: 1.6rem;
		height: 1.6rem;
		background-image: url('../img/icons/search-black.svg');
	}

	&--checked-circle {
		width: 2.4rem;
		height: 2.4rem;
		background-image: url('../img/icons/checked-circle.svg');
	}

	&--close-circle {
		width: 2.4rem;
		height: 2.4rem;
		background-image: url('../img/icons/close-circle.svg');
	}

	&--gift-circle {
		width: 2.4rem;
		height: 2.4rem;
		background-image: url('../img/icons/gift-circle.svg');
	}

	&--close-black {
		width: 1.8rem;
		height: 1.8rem;
		background-image: url('../img/icons/close-black.svg');
	}

	&--car-price {
		width: 1.6rem;
		height: 1.6rem;
		background-image: url('../img/icons/car-price.svg');
	}

	&--wheel-circle {
		width: 1.6rem;
		height: 1.6rem;
		background-image: url('../img/icons/wheel-circle.svg');
	}

	&--promotion-grey {
		width: 1.6rem;
		height: 1.6rem;
		background-image: url('../img/icons/promotion-grey.svg');
	}

	&--phone-white {
		width: 2.4rem;
		height: 2.4rem;
		background-image: url('../img/icons/phone-white.svg');
	}

	&--email-white {
		width: 2.4rem;
		height: 2.4rem;
		background-image: url('../img/icons/email-white.svg');
	}
}
