.form {
	&__group {
		position: relative;
		margin: 1rem 0 2rem;

		@include minW(768) {
			margin: 1rem 0 2rem;
		}

		&.is-filled {
			.form {
				&__label {
					transform: translateY(-3rem);
				}

				&__control {
					border-color: $black-color;
				}
			}

			.checkbox {
				&__text {
					&::before {
						border-color: $black-color;
					}
				}
			}
		}

		&.is-error {
			.form {
				&__control {
					border-color: $red-color;
				}
			}

			.select2-selection {
				border-color: $red-color;
			}

			.checkbox {
				&__text {
					&::before {
						border-color: $red-color;
					}
				}
			}
		}
	}

	&__label {
		position: absolute;
		left: 0;
		top: 1.1rem;
		margin-bottom: 0;
		color: $dark-grey-color;
		transition: transform 0.3s ease-out;
		cursor: pointer;
		font-size: 1.2rem;
		line-height: 1;
		text-transform: uppercase;
		z-index: 2;
	}

	&__control {
		cursor: pointer;
		display: block;
		width: 100%;
		outline: 0;
		-moz-appearance: none;
		-webkit-appearance: none;
		align-items: center;
		border: 0;
		border-bottom: 1px solid $light-grey-color;
		border-radius: 0;
		box-shadow: none;
		font-size: 1.6rem;
		height: 3.5rem;
		position: relative;
		background-color: #fff;
		font-weight: 500;

		&[disabled] {
			cursor: not-allowed;
		}
	}

	&__error {
		font-size: 1.2rem;
		color: $red-color;
		display: block;
	}

	&__agree {
		padding-left: 3.7rem;

		a {
			text-decoration: underline;
		}
	}

	&__noted {
		font-size: 1.2rem;
		color: $red-color;
		margin-bottom: 0;
	}

	&__inline {
		position: relative;

		.form__control {
			padding-right: 3.2rem !important;
		}

		.form__percent {
			position: absolute;
			top: 50%;
			right: 1.2rem;
			transform: translateY(-50%);
		}
	}
}
