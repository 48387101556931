$primary-font: 'MMC',
Arial,
Helvetica,
Verdana,
sans-serif !default;

$white-color: #fff !default;
$black-color: #000 !default;
$red-color: #ED0000 !default;
$grey-color: #F6F6F6 !default;
$dark-grey-color: #5A646E !default;
$cold-grey-color: #A3A9AD !default;
$light-grey-color: #C8C8C8 !default;
