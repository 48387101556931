.test-drive {
	position: relative;
	overflow: hidden;

	&.is-blur {
		opacity: 0.6;
		pointer-events: none;
		cursor: default;
	}

	&__head {
		text-align: center;
	}

	&__title {
		text-align: center;
		margin-bottom: 6rem;

		span {
			display: block;
		}
	}

	.form {
		padding: 8rem 0;
	}
}
