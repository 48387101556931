.map-work-hour {
	position: relative;
	overflow: hidden;

	&__body {
		padding: 0 2rem;

		@include minW(992) {
			padding: 0 6rem;
		}
	}

	.row {
		align-items: flex-start;
	}

	#map,
	#map-iframe {
		min-height: 32rem;
		margin-bottom: 3rem;

		@include minW(992) {
			margin-bottom: 0;
			min-height: 58rem;
		}
	}

	.taber {
		padding: 0 15px;

		&__select {
			position: relative;
			display: block;
			z-index: 2;
			margin-bottom: 16px;

			@include minW(768) {
				display: none;
			}
		}

		&__menu {
			display: none;
			margin-bottom: 4rem;

			@include minW(768) {
				display: flex;
			}
		}

		&__thumb {
			font-size: 1rem;
			background-color: $grey-color;
			margin: -6rem -2rem 4rem;
			padding: 4rem 2rem;

			@include minW(768) {
				margin: 0;
				padding: 0;
				background-color: transparent;
			}
		}

		&__content {
			ul {
				padding-left: 1.8rem;
			}

			.row {
				flex-direction: column-reverse;

				@include minW(768) {
					flex-direction: row;
				}
			}
		}
	}
}
.hasbranch {
	.map-work-hour__body {
		padding: 0;
		@include minW(992) {
			padding: 0 6rem;
		}
	}
}
