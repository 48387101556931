.breadcrumb {
	position: relative;
	overflow: hidden;
	padding: 13.6rem 0 1.6rem;

	&--top {
		padding: 16.2rem 0 1.6rem;
	}

	&__list {
		position: relative;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
		z-index: 2;
	}

	&__item {
		display: flex;
		align-items: center;
		color: $cold-grey-color;
		text-transform: uppercase;
		font-size: 1.2rem;
		line-height: 1.6rem;
		padding-right: 2rem;

		&:last-child {
			padding-right: 0;
		}

		a {
			color: $dark-grey-color;
			text-decoration: none;
		}
	}
}
