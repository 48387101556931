.promotion-detail {
	position: relative;
	padding-top: 10rem;
	iframe {
		width: 100%;
    min-height: 21rem;
		@include minW(1200) {
			min-height: 51rem;
		}
	}
	.title {
		padding: 6rem 0 2rem;
		text-align: center;
	}

	.img {
		margin-bottom: 3.6rem;
	}

	ul {
		margin-left: 2rem;
	}

	a {
		text-decoration: underline;

		&:hover {
			color: $red-color;
		}
	}

	table {

		th,
		td {
			padding: 1rem;
			border: 1px solid rgba(197, 199, 201, 0.21);
		}

		th,
		tr:nth-child(2n + 1) td {
			background-color: rgba(197, 199, 201, 0.21);
		}
	}
}

.news-detail {
	margin-top: 4rem;
	margin-bottom: 4rem;

	ul {
		margin-left: 2rem;
	}

	a {
		text-decoration: underline;

		&:hover {
			color: $red-color;
		}
	}

	table {

		th,
		td {
			padding: 1rem;
			border: 1px solid rgba(197, 199, 201, 0.21);
		}

		th,
		tr:nth-child(2n + 1) td {
			background-color: rgba(197, 199, 201, 0.21);
		}
	}
}

.video-holder {
	width: 100%;

	@include minW(1200) {
		width: 60%;
		margin: 0 auto;

		iframe {
			width: 100%;
			min-height: 38vw;
		}
	}
}
