.carousel-image {
	position: relative;
	overflow: hidden;
	padding: 4rem 0;

	&__main {
		padding-left: 2rem;

		@include minW(768) {
			padding-left: calc(8.3333% + 2rem);
		}
	}

	&__paging {
		text-align: left;
		font-size: 1rem;
		line-height: 1.6rem;
		letter-spacing: 2px;
		color: $dark-grey-color;
		margin: 1.4rem 0;

		@include minW(768) {
			font-size: 1.2rem;
			text-align: right;
			padding-right: calc(8.3333% + 4rem);
		}

		span {
			font-size: 1.4rem;
			margin: 0 3px;
			color: $black-color;
		}
	}

	.slick {
		&-list {
			padding-right: 12%;

			@include minW(768) {
				padding-right: 8.33333%;
			}
		}

		&-track {
			margin: 0 -1rem;

			@include minW(768) {
				margin: 0 -2rem;
			}
		}

		&-slide {
			padding: 0 1rem;

			@include minW(768) {
				padding: 0 2rem;
			}
		}

		&-arrow {
			width: 3.2rem;
			height: 3.2rem;
			background: none;
			background-color: #fff;
			top: 50%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
			justify-content: center;

			@include hover {
				background: none;
				background-color: #fff;
			}

			@include minW(768) {
				width: 6.4rem;
				height: 6.4rem;
			}

			&:before {
				content: '';
				display: block;
				width: 0.8rem;
				height: 0.8rem;

				@include minW(768) {
					width: 1.4rem;
					height: 1.4rem;
				}
			}
		}

		&-next {
			right: 0;

			&::before {
				border-right: 3px solid $dark-grey-color;
				border-bottom: 3px solid $dark-grey-color;
				transform: rotate(-45deg);
			}
		}

		&-prev {
			left: 0;

			&::before {
				border-left: 3px solid $dark-grey-color;
				border-bottom: 3px solid $dark-grey-color;
				transform: rotate(45deg);
			}
		}
	}
}
