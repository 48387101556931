.navigation {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 6rem;
	background-color: $black-color;
	display: flex;
	align-items: center;
	transform: translateY(100%);
	transition: transform 0.2s ease-out;
	z-index: 97;

	&.is-sticky {
		transform: translateY(0%);
	}

	&.is-docked {
		position: absolute;
		bottom: auto;
	}
}

.navigation-menu {
	// display: flex;
	// align-items: center;
	// justify-content: space-between;
	// list-style: none;
	// padding: 0;
	// margin: 0;
	// text-align: center;

	// @include minW(768) {
	// 	justify-content: space-around;
	// }

	&__item {
		margin: 0 5%;
	}

	&__link {
		color: $cold-grey-color;
		font-size: 1.2rem;
		line-height: 1.1;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		transition: color 0.3s ease-out;
		white-space: nowrap;
		@include hover {
			color: $red-color;

			.navigation-menu {
				&__icon {
					.img {
						&--red {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	&__icon {
		position: relative;
		width: 2.4rem;
		height: 2.4rem;
		margin-bottom: 5px;
		display: flex;
		align-items: center;
		justify-content: center;

		.img {
			position: absolute;
			height: 100%;
			transition: color 0.3s ease-out;

			&--red {
				opacity: 0;
			}
		}
	}

	.slick {
		&-arrow {
			background: none;
			width: 1rem;
			height: 1rem;
			top: 50%;
			transform: translateY(-50%);

			&::before {
				display: block;
				content: '';
				width: 1rem;
				height: 1rem;
				border-left: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}

		&-next {
			right: 0;

			&::before {
				transform: rotate(-135deg);
			}
		}

		&-prev {
			left: 0;

			&::before {
				transform: rotate(45deg);
			}
		}
	}
}
