.test-drive-checkin {
	position: relative;
	width: 100%;
	max-width: 400px;
	margin: 8vw auto 0;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	.checkbox {
		margin-bottom: 0;
		.checkbox__text a {
			text-decoration: underline;
		}
	}

	&__main {
		width: 100%;
		padding: 0 2rem;
	}

	&__logo {
		display: flex;
		justify-content: center;
		margin-bottom: 3.2rem;

		.img {
			min-width: 12rem;
		}
	}

	.btn {
		@include minW(768) {
			width: 100%;
			padding: 1.2rem 2.6rem;
		}
	}
	.select2.select2-container.select2-container--default {
		width: 100% !important;
		text-align: left;
	}
	.form__group {
		margin: 1rem 0 3rem;
	}
	.variant-box__content {
		// text-align: left;
		border-color: $red-color;
		background-color: #f6f6f6;
		margin-bottom: 3rem;
		&::before {
			content: none;
		}
	}
}
.thank-you__sttname {
	display: block !important;
	width: 100%;
	padding-bottom: 2.5vw;
	padding-top: 1.5vw;
	// color: $red-color;
}
.grecaptcha-badge {
	display: none !important;
}

.file-progress {
	background-color: #eff0f0;
	padding: 1.6rem;
	display: none;

	&__text {
		margin-bottom: 0.8rem;
		line-height: 1.3;
	}

	&__bar {
		background-color: #fff;
		border-radius: 1.6rem;
		height: 1rem;
		overflow: hidden;
		position: relative;
		margin-bottom: 0.8rem;
	}

	&__percent {
		background-color: #fc3640;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		overflow: hidden;
		border-radius: 16px;

		&::before {
			content: '';
			display: block;
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			background-color: #fff;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}

.image-preview {
	display: none;
	position: relative;

	&__body {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	&__remove {
		width: 32px;
		height: 32px;
		font-size: 0;
		background-color: transparent;
		border: 0;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before,
		&::after {
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: #000;
			position: absolute;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}

.signature {
	display: flex;
	flex-direction: column;
	align-items: center;

	&.is-show {
		align-items: flex-end;

		.signature {
			&__body {
				opacity: 1;
				pointer-events: all;
				cursor: pointer;
			}
		}

		.btn--link.signature__add {
			color: #686d71;

			&::after {
				background-color: #686d71;
			}
		}
	}

	&__clear {
		opacity: 0;
		visibility: hidden;
		margin-bottom: 0.5rem;
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		opacity: 0.5;
		pointer-events: none;
		cursor: default;
		width: 100%;

		canvas {
			border: 1px solid $cold-grey-color;
			display: block;
			width: 100%;
		}
	}

	&__note {
		background-color: #f6f6f6;
		padding: 16px;
		margin-top: 16px;
		font-size: 14px;
		line-height: 1.4;
		margin-bottom: 0;
		display: none;
	}
}

.test-drive-checkin {
	.btn {
		&--link {
			padding: 0.5rem 0 !important;
		}
	}
}

.file-browser {
	position: relative;

	label {
		display: block;
		font-weight: 700;
		//margin-bottom: 1.6rem;
		position: relative;

		span {
			display: block;
			font-size: 1.2rem;
			line-height: 1.4;
			font-weight: 400;
		}

		input {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			width: 100%;
			height: 100%;
		}
	}

	&__text {
		border: 1px dashed $dark-grey-color;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: 1.6rem;
		padding: 0.8rem;
		margin-top: 0.8rem;
		position: relative;
	}

	&__remove {
		width: 32px;
		height: 32px;
		font-size: 0;
		background-color: $light-grey-color;
		border: 1px solid $light-grey-color;
		position: absolute;
		top: -16px;
		right: -16px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;

		&::before,
		&::after {
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: #000;
			position: absolute;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}
}

.icon-file {
	display: block;
	width: 4.8rem;
	height: 4.8rem;

	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
}

.file-upload-image {
	&__hidden {
		display: none;
	}
}

.btn-input-file {
	margin-bottom: 1.6rem;
}

.btn--resend-signature {
	border: 0 !important;

	&.is-disabled {
		&::after {
			background-color: #c8c8c8 !important;
		}
	}

	.btn__count {
		padding-left: 0.8rem;
	}
}

.phone-signature {
	color: #fc3640;
}

.info-step4-otp {
	.form__code {
		margin-bottom: 0;
	}
}

.test-drive__title {
	.phone-signature {
		display: inline-block !important;
	}
}
