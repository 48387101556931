.cost-estimate-summary {
	position: relative;
	overflow: hidden;
	padding-bottom: 8rem;
	display: none;

	&__head {
		position: relative;
		padding-top: 4rem;

		.btn {
			width: 100%;
			margin-bottom: 2rem;
		}
	}

	&__bg {
		position: absolute;
		top: 0;
		left: 50%;
		width: 100%;
		height: 100%;
		display: none;
		background: #f7f7f7;
		background: -moz-linear-gradient(top, #f7f7f7 0%, #fbfbfb 100%);
		background: -webkit-linear-gradient(top, #f7f7f7 0%, #fbfbfb 100%);
		background: linear-gradient(to bottom, #f7f7f7 0%, #fbfbfb 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f7f7f7', endColorstr='#fbfbfb', GradientType=0);
		transform: skewX(-25deg);

		@include minW(992) {
			display: block;
		}
	}

	&__view {
		position: relative;
		z-index: 2;
	}

	&__body {
		background-color: #F7F7F7;
		padding: 6rem 0 3rem;
	}
}

.ces-summary {
	position: relative;
	margin-bottom: 11.5rem;

	@include minW(992) {
		margin-bottom: 0;
	}

	&__content {
		margin-bottom: 6rem;
	}

	&__text {
		margin-bottom: 1rem;
	}

	&__value {
		text-transform: uppercase;
		margin-left: 5px;
	}
}

.ces-bill {
	position: relative;

	&__item {
		display: flex;
		align-items: center;
		padding: 4rem 0;
		border-bottom: 1px solid $cold-grey-color;

		.title {
			margin-bottom: 0;
			width: 60%;
		}

		p {
			margin-bottom: 0;
			color: $dark-grey-color;
			width: 40%;
			text-align: right;
		}
	}

	&__result {
		padding: 6rem 0;
		text-align: right;

		.title {
			span {
				display: inline-block;
			}
		}
	}

	&__button {
		.btn {
			width: 100%;
			margin-top: 3rem;

			@include minW(992) {
				width: auto;
				margin-left: 3rem;
			}

			&:first-child {
				margin-top: 0;

				@include minW(992) {
					margin-left: 0;
				}
			}
		}
	}
}

.ces-noted {
	font-size: 1.4rem;
	line-height: 2.4rem;
	color: $dark-grey-color;
	margin: 6rem 0 0;
}
