.contact-info {
	position: relative;
	overflow: hidden;
	color: $white-color;

	&__main {
		position: relative;
		background-color: $black-color;
	}

	&__list {
		padding: 4rem 2rem;

		i {
			margin-right: 1rem;
		}

		@include minW(992) {
			padding: 14rem 0;
		}
	}

	&__item {
		margin-bottom: 5rem;

		&--map {
			.title {
				cursor: pointer;
				transition: color 0.3s ease-out;

				@include hover {
					color: $red-color;
				}

				&.is-active {
					color: $red-color;
				}
			}
		}
	}

	#map {
		min-height: 37.5rem;

		@include minW(768) {
			min-height: 76.8rem;
		}

		@include minW(992) {
			height: 100%;
		}
	}
}
.is-branch {
	.contact-info__list {
		@include minW(992) {
			padding: 4rem 2rem !important;
		}
	}
}
