.dealer-network {
	position: relative;
	overflow: hidden;
	width: 100%;
	min-height: 100vh;

	&__main {
		display: none;
	}

	&__body {
		display: flex;
		flex-wrap: wrap;
	}

	&__item {
		position: relative;
		width: 100%;
		overflow: hidden;
		padding-top: 14.6rem;

		@include minW(992) {
			height: 100vh;
			width: 50%;
		}

		.location,
		&--map {
			display: none;

			@include minW(992) {
				display: block;
			}
		}
	}

	&__find {
		position: relative;

		@include minW(992) {
			height: 100%;
		}
	}

	&__list {
		position: relative;
		width: 100%;
		overflow-y: auto;
		padding: 4rem 0 2rem 2rem;

		@include minW(992) {
			height: 100%;
			padding-bottom: 0;
		}

		@include minW(1400) {
			padding: 4rem 0 0 5.2rem;
		}
	}

	&__detail {
		position: relative;
		background-color: $white-color;
		display: none;

		@include minW(992) {
			height: 100%;
		}

		.map-view {
			display: block;

			@include minW(992) {
				display: none;
			}
		}
	}

	&__content {
		position: relative;
		width: 100%;
		overflow-y: auto;
		padding: 4rem 2rem 2rem;

		@include minW(992) {
			height: 100%;
			padding: 4rem 5.2rem 6rem;
		}
	}
}

#map,
#map1,
#map2 {
	width: 100%;
	min-height: 45.6rem;
	height: 100%;
}

.region {
	position: relative;
	margin-bottom: 5rem;

	@include minW(992) {
		margin-bottom: 7rem;
	}

	&__list {
		display: none;
		color: #404040;
		text-transform: uppercase;
		font-size: 1.6rem;
		line-height: 2.6rem;
		margin: 6.5rem 0;

		@include minW(992) {
			display: flex;
		}

		@include minW(1400) {
			display: flex;
			font-size: 2.1rem;
			line-height: 3rem;
			margin: 6.5rem 0;
		}
	}

	&__location {
		padding-right: 2rem;
	}

	&__item {
		position: relative;
		margin-left: 3.5rem;
		cursor: pointer;
		transition: color 0.3s ease-out;

		@include minW(1200) {
			margin-left: 6.5rem;
		}

		@include hover {
			color: $red-color;

			&::after {
				width: 100%;
			}
		}

		&.is-active {
			pointer-events: none;
			cursor: default;

			&::after {
				width: 100%;
			}
		}

		&:first-child {
			margin-left: 0;
		}

		&::after {
			content: '';
			display: block;
			width: 0;
			height: 2px;
			background-color: $red-color;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			transition: width 0.3s ease-out;
		}
	}

	&__select {
		display: block;
		margin-bottom: 5rem;

		@include minW(992) {
			display: none;
		}

		.select {
			max-width: 22.6rem;

			.select2-selection {
				border: 1px solid $black-color;

				&__rendered {
					padding-left: 1.2rem;
				}

				&__arrow {
					right: 1.2rem;
				}
			}
		}
	}
}

.location {
	&__item {
		position: relative;
		border-bottom: 1px solid #b7b7b7;
		//margin: 2rem 0;
		padding: 2.6rem 5rem;
		font-size: 1.4rem;
		line-height: 1.8rem;
		cursor: pointer;

		@include minW(992) {
			padding: 2rem 16rem 2rem 6rem;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include hover {
			background-color: $grey-color;

			.icon {
				background-image: url('../img/icons/location-red-bg.svg');
			}
		}

		.icon {
			position: absolute;
			left: 0;
			top: 2.6rem;
			color: $white-color;
			display: flex;
			justify-content: center;
			align-items: center;
			font-style: normal;
			padding-bottom: 8px;

			@include minW(992) {
				left: 1rem;
				top: 2rem;
			}
		}
	}

	&__body {
		.title {
			text-transform: uppercase;
			font-weight: 400;
			margin-bottom: 8px;
		}
	}

	&__address {
		margin-bottom: 8px;
	}

	&__text {
		display: flex;

		span {
			position: relative;
			display: block;
			margin-left: 4.4rem;

			&::before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: $black-color;
				position: absolute;
				left: -2.2rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&:first-child {
				margin-left: 0;

				&::before {
					display: none;
				}
			}
		}
	}

	&__km {
		margin-top: 1rem;

		@include minW(992) {
			margin-top: 0;
			position: absolute;
			right: 5.3rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.location-detail {
	&__item {
		position: relative;
		border-bottom: 1px solid #b7b7b7;
		padding: 2.6rem 5rem;
		font-size: 1.4rem;
		line-height: 1.8rem;
		cursor: pointer;

		@include minW(992) {
			padding: 2rem 16rem 2rem 6rem;
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include hover {
			background-color: $grey-color;
		}

		.icon {
			position: absolute;
			left: 0;
			top: 2.6rem;
			color: $white-color;
			display: flex;
			justify-content: center;
			align-items: center;
			font-style: normal;
			padding-bottom: 8px;

			@include minW(992) {
				left: 1rem;
				top: 2rem;
			}
		}
	}

	&__address {
		margin-bottom: 1.5rem;
	}

	&__text {
		display: flex;
		margin-bottom: 2rem;

		span {
			a {
				text-decoration: underline;
				display: inline-block;
				margin-left: 0.3vw;
			}
			position: relative;
			display: block;
			margin-left: 4.4rem;

			// @include minW(992) {
			// 	margin-left: 4.4rem;
			// }

			&::before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: $black-color;
				position: absolute;
				left: -2.2rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&:first-child {
				margin-left: 0;

				&::before {
					display: none;
				}
			}
		}
	}

	&__km {
		margin-top: 1rem;

		@include minW(992) {
			margin-top: 0;
			position: absolute;
			right: 5.3rem;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.title {
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	.btn {
		font-size: 1.4rem;
		line-height: 1.8rem;
		text-transform: none;
		font-weight: 400;
	}
}

.dealer-box {
	position: relative;

	&__item {
		border-bottom: 1px solid #b7b7b7;
		margin-bottom: 4.5rem;
		padding-bottom: 4.5rem;
		font-size: 1.4rem;
		line-height: 1.8rem;
	}
}

.dealer-contact {
	position: relative;

	p {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		line-height: 2.4rem;
		padding-left: 2.4rem;
		margin-bottom: 5px;

		.icon {
			position: absolute;
			left: 0;
			top: 8px;
		}

		a {
			transition: all 0.3s ease-out;

			@include hover {
				color: $red-color;
			}
		}
	}

	&__text {
		margin-bottom: 0;
		display: flex;
		align-items: center;

		span {
			position: relative;
			display: block;
			margin-left: 4rem;

			&::before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: $black-color;
				position: absolute;
				left: -2rem;
				top: 50%;
				transform: translateY(-50%);
			}

			&:first-child {
				margin-left: 0;

				&::before {
					display: none;
				}
			}
		}
	}
}

.dealer-time {
	position: relative;

	p {
		display: flex;
		flex-wrap: nowrap;
		margin-bottom: 0;
		line-height: 2.4rem;

		span {
			display: block;
			width: 25%;

			&:last-child {
				margin-left: 2rem;
				width: 75%;
			}
		}
	}

	.row__col {
		margin-bottom: 4.6rem;

		@include minW(992) {
			margin-bottom: 0;
		}
	}
}

.dealer-button {
	margin-bottom: 4rem;

	@include minW(992) {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;
	}

	&__item {
		margin-bottom: 2rem;

		@include minW(992) {
			width: 50%;
			padding: 0 5px;
		}

		.btn {
			width: 100%;
		}
	}
}

.dealer-back {
	font-size: 0;
	width: 4.4rem;
	height: 4.4rem;
	border-radius: 50%;
	border: 1px solid $dark-grey-color;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 6rem;
	cursor: pointer;

	&::before {
		content: '';
		display: block;
		width: 1.2rem;
		height: 1.2rem;
		border-left: 3px solid $dark-grey-color;
		border-bottom: 3px solid $dark-grey-color;
		transform: rotate(45deg);
		margin-left: 6px;
	}
}

.dealer-km {
	position: absolute;
	top: 4rem;
	right: 4rem;
	text-align: center;
	font-size: 1.4rem;
	line-height: 1.8rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	.icon {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 8px;
		margin-bottom: 1.8rem;
		color: $white-color;
		font-style: normal;
	}
}

.dealer-current {
	font-size: 1.4rem;
	line-height: 1.7rem;
	font-weight: 700;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	cursor: pointer;

	@include minW(992) {
		margin-bottom: 7rem;
	}

	.icon {
		margin-right: 1rem;
	}
}

.location-result {
	color: $dark-grey-color;
	font-size: 1.6rem;
	line-height: 1.6rem;
	border-bottom: 1px solid #b7b7b7;
	padding-bottom: 2rem;
}

.dealer-network-mobile {
	display: block;

	@include minW(992) {
		display: none;
	}

	&.is-mobile {
		display: none;
	}

	.taber {
		&__link {
			border: 1px solid #c4c4c4;
			width: 50%;
			margin: 0;
			text-align: center;
			font-size: 1.6rem;
			line-height: 1.8rem;
			font-weight: 700;
			padding: 1.2rem;
			opacity: 1;

			&::after {
				display: none;
			}

			&.is-active {
				border: 1px solid $black-color;
				background-color: $black-color;
				color: $white-color;
			}
		}
	}
}

.map-view {
	margin: 0 -2rem;
}

.marker-label {
	text-align: center;
	color: #fff !important;
}
