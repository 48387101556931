.specification {
	position: relative;
	overflow: hidden;
	padding-bottom: 4rem;

	&.posFix {
		&.scroll-up {
			.specification {
				&__body {
					top: 10rem;
				}
			}
		}

		.specification {
			&__body {
				position: fixed;
				top: 0;

				.bullet {
					display: none;
				}
			}
		}

		.specification-car {
			&__main {
				padding: 2rem 0;
			}

			&__bg {
				width: 100%;
				background-color: #f5f5f5;
			}

			&__thumb {
				max-height: 0;
			}
		}
	}

	&.posAbs {
		.specification {
			&__body {
				position: absolute;
				bottom: 0;

				.bullet {
					display: none;
				}
			}

			&__foot {
				padding-top: 12rem;
			}
		}

		.specification-car {
			&__main {
				padding: 2rem 0;
			}

			&__bg {
				width: 100%;
			}

			&__thumb {
				max-height: 0;
			}
		}
	}

	&--compare {
		padding-top: 14rem;
	}

	&__head {
		text-align: center;
		margin-bottom: 4rem;
	}

	&__main {
		overflow-x: auto;
		margin: 0 -2rem;
	}

	&__body {
		position: relative;
		margin-bottom: 4rem;
		z-index: 2;
		transition: top 0.3s ease-out;
		padding: 0 2rem;

		@include minW(576) {
			padding: 0;
		}

		.row {
			flex-wrap: nowrap;
			background-color: #fff;

			&__col {
				min-width: 30rem;
				width: 30rem;
				padding: 0 2rem;
				max-width: unset;

				@include minW(576) {
					flex: 1 0 0%;
					width: auto;
					max-width: unset;
				}
				&.ooo {
					width: 100%;
					@include minW(576) {
						flex: 1 0 0%;
						width: auto;
						max-width: unset;
					}
				}
			}
		}
	}

	&__foot {
		.collapse {
			padding: 0 2rem;

			@include minW(576) {
				padding: 0;
			}

			&__body {
				margin: 0 -2rem;

				@include minW(576) {
					margin: 0;
				}
			}

			&__content {
				padding: 2rem;

				@include minW(576) {
					padding: 2rem 0;
					overflow-x: hidden;
				}
			}
			&.ooo {
				width: 100% !important;
				@include minW(576) {
					flex: 1 0 0%;
					width: auto !important;
					max-width: unset;
				}
			}
		}
	}
}

.specification-car {
	position: relative;

	&__bg {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		overflow: hidden;
		width: 65%;
		display: flex;
		backface-visibility: hidden;
		flex-direction: column;
		flex: 1 1 auto;
		height: 100%;
		z-index: 1;
		/* display: none; */
	}

	&__inner {
		background-repeat: no-repeat;
		transition: opacity 0.15s ease-out 0.45s;
		background-color: #f5f5f5;
		background-position: 50%;
		background-size: cover;
		flex: 1 1 auto;
		position: relative;
		transition-delay: 0ms;
		display: none;

		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: #f5f5f5;
		}
	}

	&__main {
		position: relative;
		padding: 0;
		text-align: center;
		display: block;
		z-index: 2;
		transition: all 0.3s ease-out;
	}

	&__thumb {
		overflow: hidden;
		max-height: 100rem;
		transition: max-height 0.3s ease-out;
		max-width: 33.33vw;
		margin: 0 auto;
	}

	.title {
		margin-bottom: 5px;
	}
}

.box-size {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0 -2rem 2rem;
	flex-wrap: nowrap;

	// &--2 {
	// 	.box-size {
	// 		&__item {
	// 			@include minW(576) {
	// 				min-width: 50%;
	// 				width: 50%;
	// 			}
	// 		}
	// 	}
	// }

	// &--3 {
	// 	.box-size {
	// 		&__item {
	// 			@include minW(576) {
	// 				min-width: 33.3333%;
	// 				width: 33.3333%;
	// 			}
	// 		}
	// 	}
	// }

	// &--4 {
	// 	.box-size {
	// 		&__item {
	// 			@include minW(576) {
	// 				min-width: 25%;
	// 				width: 25%;
	// 			}
	// 		}
	// 	}
	// }

	&__item {
		min-width: 30rem;
		width: 30rem;
		padding: 0 2rem;
		display: flex;
		flex-direction: column;
		flex: 1 0 0%;
		width: auto;
		max-width: unset;

		// @include minW(576) {
		// 	min-width: 100%;
		// 	width: 100%;
		// }
	}

	&__text {
		padding: 2rem 3rem;
		display: block;
		background-color: #f5f5f5;
		text-align: center;
		display: flex;
		flex: 1 0 auto;
		align-items: center;
		justify-content: center;
	}
}
