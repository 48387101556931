.loan-calculator {
	position: relative;
	overflow: hidden;

	&.is-blur {
		>* {
			opacity: 0.5;
			pointer-events: none;
			cursor: default;
		}
	}

	&__head {
		text-align: center;
		padding: 15rem 0 10rem;
	}

	&__note {
		color: $red-color;
	}

	&__item {
		&:first-child {
			padding-top: 4rem;
		}

		&:nth-child(2),
		&:nth-child(3) {
			display: none;
		}
	}

	.form {
		&__group {
			&--customer {
				.form {
					&__label {
						position: relative;
						left: auto;
						top: auto;
						transform: none !important;
						font-size: 1.8rem;
						line-height: 2.5rem;
						font-weight: 500;
						margin-bottom: 1.2rem;
						margin-top: 3rem;
					}

					&__control {
						border: 1px solid #ccc;
						padding: 2.3rem 1.7rem;
						height: 6.2rem;
					}
				}
			}
		}
	}
}

.financial-plan {
	position: relative;

	.row {
		&__col {
			margin-bottom: 4rem;
		}
	}

	&__item {
		width: 100%;
		background-color: #f6f6f6;
		height: 100%;


		&.is-active {
			.financial-plan {
				&__radio {
					&::before {
						background-color: $red-color;
					}
				}
			}
		}
	}

	&__radio {
		position: absolute;
		top: 3rem;
		right: 3rem;
		width: 2.2rem;
		height: 2.2rem;
		border: 1px solid #C8C8C8;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;

		&::before {
			content: '';
			display: block;
			width: 1.6rem;
			height: 1.6rem;
			border-radius: 50%;
		}
	}

	&__body {
		position: relative;
		padding: 2.5rem;
		border-top: 4px solid $black-color;
	}

	&__title {
		font-size: 2rem;
		line-height: 2.8rem;
		margin-bottom: 2.5rem;
		font-weight: 500;
		padding-right: 5rem;
	}

	&__subtitle {
		font-size: 2rem;
		line-height: 1.4rem;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 2.5rem;
		color: #5A646E;
	}

	&__price {
		font-size: 2rem;
		line-height: 2.8rem;
		font-weight: 500;
		margin-bottom: 2.5rem;

		span {
			font-size: 1.6rem;
			font-weight: 400;
			line-height: 2.8rem;
			display: inline-block;
			margin-left: 1rem;
		}
	}

	&__content {
		p:last-child {
			margin-bottom: 0;
		}
	}
}

.loan-detail {
	position: relative;
	overflow: hidden;
	padding: 3rem 0 6rem;
	display: none;

	&__head {
		text-align: center;
		margin-bottom: 6rem;
		letter-spacing: 2px;

		p {
			margin-bottom: 0;
		}
	}

	&__body {
		position: relative;
		overflow: hidden;
		overflow-x: auto;
		padding: 0 2rem;
		margin: 0 -2rem;

		@include minW(992) {
			padding: 0;
			margin: 0;
		}

		table {
			border-collapse: collapse;
			width: 100%;
			max-width: 100%;
			min-width: 64rem;

			th,
			td {
				padding: 2rem;
				border: 1px solid $white-color;
			}

			thead {
				background-color: $black-color;
				color: $white-color;
				font-size: 1.4rem;
				line-height: 1.8rem;
				letter-spacing: 0.2px;
				text-transform: uppercase;
			}

			tbody {
				color: $dark-grey-color;

				tr {
					&:nth-of-type(odd) {
						background-color: $grey-color;
					}
				}
			}
		}
	}
}

.select-model-message {
	margin-bottom: 30px;

	p {
		position: relative;
		padding: 2.5rem;
		width: 100%;
		background-color: #f6f6f6;
		height: 100%;
		border-top: 4px solid #000;
		text-align: center;
		margin: 0 auto;

		@include minW(992) {
			width: 50%;
		}
	}
}

.custom-interest {
	display: none;

	&.is-show {
		display: block;
	}

	.form {
		&__group {
			margin: 0;
		}
	}
}
