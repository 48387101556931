.modal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	overflow: hidden;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 99;

	&.is-show {
		visibility: visible;
		opacity: 1;
	}

	&.is-scroll {
		align-items: flex-start;
		overflow-y: auto;
	}

	&--time {
		text-align: center;
	}

	&--xpander {
		.modal {
			&__close {
				.icon--close-black {
					background-image: url(../img/icons/close-white.svg);

					@include minW(992) {
						background-image: url(../img/icons/close-black.svg);
					}
				}
			}

			&__dialog {
				max-width: 100rem;
				padding: 0;

				@include minW(992) {
					padding: 0 2.4rem 0 0;
				}
			}

			&__body {
				@include minW(992) {
					display: flex;
					margin: 0 -1.2rem;
				}
			}

			&__thumb {
				@include minW(992) {
					width: 65%;
					padding: 0 1.2rem;
				}
			}

			&__desc {
				font-size: 1.2rem;
				margin-bottom: 5px;
				text-align: center;

				@include minW(992) {
					text-align: left;
				}
			}

			&__content {
				padding: 2rem;

				@include minW(992) {
					width: 35%;
					padding: 0 1.2rem;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.title {
					margin-bottom: 5px;
					line-height: 2.6rem;
					text-align: center;

					@include minW(992) {
						text-align: left;
					}

					span {
						text-transform: uppercase;
						display: inline-block;

						&:last-child {
							display: block;
						}

						@include minW(992) {
							display: block;
						}
					}
				}

				.select {
					margin: 0;
				}

				.select2-selection {
					&__rendered {
						text-transform: none;

						&--value {
							font-size: 1.2rem;
							font-weight: 400;
						}
					}
				}

				.form {
					&__group {
						margin: 0;

						&--center {
							text-align: center;
						}

					}

					&__control {
						font-weight: 400;
						font-size: 1.2rem;
					}

					&__agree {
						padding-left: 0;
						font-size: 1rem;
						line-height: 1.4rem;
						padding-top: 5px;
						margin-bottom: 2rem;
						text-align: center;

						@include minW(992) {
							text-align: left;
							margin-bottom: 1rem;
						}
					}

					&__error {
						min-height: 1.4rem;
						font-size: 1rem;
						line-height: 1.4rem;
					}
				}
			}

			&__form {
				margin: 0 auto;

				.row {
					margin: 0 -8px;

					&__col {
						padding: 0 8px;
					}
				}
			}

			&__result {
				display: none;
			}
		}
	}

	&--configurator {
		.modal {
			&__dialog {
				padding: 3rem;
			}

			&__button {
				.btn+.btn {
					@include minW(992) {
						margin-left: 4rem;
					}
				}
			}
		}
	}

	&--otp {
		text-align: center;

		.modal {
			&__dialog {
				padding: 3rem;
			}

			&__close {
				display: none;
			}
		}

		.title {
			margin-bottom: 6rem;
		}

		.form {

			&__group:first-child {
				margin: 0 auto;

				@include minW(576) {
					width: 40%;
				}
			}

			&__control,
			&__label {
				text-align: center;
				width: 100%;
			}

			&__code {
				margin-bottom: 0;
			}
		}

		.btn {
			text-transform: none;

			&.is-disabled {
				color: $cold-grey-color;
				border: 0;

				&::after {
					background-color: $cold-grey-color;
				}
			}

			&__count {
				padding-left: 5px;
			}
		}
	}

	&__dialog {
		width: 100%;
		max-width: 80rem;
		padding: 10rem 3rem 3rem;
		background-color: #fff;
		position: relative;

		@include minW(992) {
			margin: 3rem 0;
		}
	}

	&__close {
		background-color: transparent;
		border: 0;
		outline: 0;
		position: absolute;
		top: 2.8rem;
		right: 2.8rem;

		@include hover {
			outline: 0;
		}
	}
}
